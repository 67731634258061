import { Outlet, Link } from "react-router-dom";
import React from "react";
import Home from "../pages/Home";
import Header from "../components/Header";
import Footer from "../components/Footer";
//Mobile imports
import "antd/dist/antd.css";
//Images
import MainLogo from "../static/Home/main-logo.svg";
if (window.innerWidth <= 440 || window.innerHeight <= 440) {
  window.isPhone = true;
} else {
  window.isPhone = false;
}

const Layout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Home />
      <Footer />
    </>
  );
};
export default Layout;
